"use client";
import React, { useState, useEffect, useRef } from "react";
import { useRouter } from "next/navigation";
import { Button } from "~/components/ui/button";
import Link from "next/link";
import { ComponentNotFoundInCMS } from "~/components/cms-not-found";
import { Input } from "~/components/ui/input";
import {
  SearchIcon,
} from "~/components/icons";

export const SearchBar = ({ blogCategory }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [input, setInput] = useState("");
  const router = useRouter();

  const searchRef = useRef<any | null>(null);

  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      router.push(`/blog/search/${input}`);
      setInput("");
    }
  };
  if (blogCategory) {
    return (
      <>
        <div className="absolute top-0 z-10 h-[74px] w-full bg-gradient-to-r from-[#9345E1] via-[#05BAD1] to-[#76BA9A]"></div>
        <div className="absolute top-0 z-20 h-[74px] w-full bg-gradient-to-b from-black via-[#000000c6] to-[#0000009f]"></div>
        <div
          className="container sticky top-0  z-50 flex h-[80px] w-full text-white md:h-full md:items-center md:justify-center"
          ref={searchRef}
        >
          <div className="relative flex w-full flex-col rounded-md">
            <div className={`z-50 ${isSticky ? '' : 'mt-[25px]'} flex w-full flex-col items-center gap-5 rounded-md border-0 border-white bg-gradient-search-bar px-4 py-2 text-white md:h-1/2 md:border-2 md:py-4`}>
              <div className="flex w-full items-center justify-between gap-3 md:justify-around">
                <Button
                  className=" hover:bg-opacity-10"
                  variant={"ghost"}
                  size="icon"
                  asChild
                  aria-label="Search"
                >
                  <Link href={`/blog/search/${input}`}>
                    <SearchIcon color="white" size={40} />
                  </Link>
                </Button>
                <Input
                  placeholder="Search Blog"
                  className="border-none text-white placeholder:text-white"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <ComponentNotFoundInCMS message="Section could not be loaded" />;
  }
};
